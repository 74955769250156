/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 */
 /* src/global.css */
:root {
  --primary-color:#987A44;
  --secondary-color:#044862;
  --font-family: 'Arial, sans-serif';
  --font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  background-color: #f5f5f5;
  color: #333;
}

