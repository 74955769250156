/* styles.css */
.container2 {
  
    font-family: Arial, sans-serif;
    display: flex;
    gap:50px;
    
    background-color: rgb(26, 76, 97);
    
   
   
    

    
  }
  .bg-color{
    background-color: aqua;

  }
  
  
  .data-section {
    margin-bottom: 30px;
    height: 100vh;
    overflow: scroll;
}

.data-section::-webkit-scrollbar {
    width: 0;  /* This will hide the vertical scrollbar */
    height: 0; /* This will hide the horizontal scrollbar */
}




  
  .data-section h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .data-section p {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .data-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .data-section li {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
  
  .data-section li p {
    margin: 0;
  }
  
  .data-section li:hover {
    background-color: #e0e0e0;
  }
  
  /* Additional Styles */
  
  .data-section h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .data-section li p:first-child {
    margin-top: 5px;
  }
  
  .data-section li p:last-child {
    margin-bottom: 5px;
  }
  
  .data-section h2::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin-top: 5px;
  }
  
  .data-section h3::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin-top: 5px;
  }
  
  .data-section ul {
    margin-top: 10px;
  }
  
  .data-section ul li {
    background-color: #fff;
  }
  
  .data-section ul li:hover {
    background-color: #f0f0f0;
  }
  