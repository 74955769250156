.suggestions-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .suggestions {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    width: 450px;
  }
  
  .suggestion {
    cursor: pointer;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .suggestion:hover {
    background-color: #f0f0f0;
    border: 1px solid rgba(0, 0, 0, 0.34);
  }
  .comment-input{
    margin: 5px;

  }
  .close-modal{
    display: flex;
    justify-content: flex-end;
    font-size: x-large;
    position: relative;
    top: -2em;
    left: 9px;
  }
  .centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Optionally set a height for the container */
  }
  .centered-image {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    max-height: 100%; /* Ensure the image doesn't exceed its container's height */
  }
  

 